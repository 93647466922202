import { getIsValidId } from '@/store/asyncActions';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useContextState } from '../../context/auth';
import Loader from '../Common/Loader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/index';

type Props = {
  children: JSX.Element;
  accessType: 'authenticated' | 'anonymous' | null;
};

export function AuthGuard({ accessType, children }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidId, setIsValidId] = useState(true); // Assume valid if no id present
  const contextState = useContextState();
  // const signedIn = contextState?.loginObject?.auth_token?.length !== 0

  const status = contextState?.loginStatus;

  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const { id, tabId } = router.query; // Destructure both id and tabId from router.query

  useEffect(() => {
    const isValidIdCheck = async (id: any) => {
      try {
        setIsLoading(true); // Start loading
        const res = await getIsValidId(id as any); // Replace with actual validation logic
        if (res.status === 'success') {
          setIsValidId(true); // Mark ID as valid
        } else {
          setIsValidId(false); // Mark ID as invalid
          router.push('/page_not_found'); // Redirect to not found page if validation fails
        }
      } catch (err) {
        console.error('Error validating ID:', err);
        setIsValidId(false); // Mark ID as invalid on error
        router.push('/page_not_found'); // Redirect to not found page on error
      } finally {
        setIsLoading(false); // Always stop loading when validation completes
      }
    };

    const checkAuthentication = async () => {
      setIsLoading(true); // Start loading

      if (accessType === 'authenticated') {
        if (status === 'loggedOut') {
          // Redirect to sign-in page immediately if not signed in
          router.replace('/sign_in');
          return; // Exit early to prevent further execution
        }

        
        // If signed in and ID exists, validate the ID
        if (id) {
          await isValidIdCheck(id);
        } else {
          setIsValidId(true); // No ID to validate, assume valid
        }
      } else if (accessType === 'anonymous') {
        if (status === 'loggedIn') {
          // Redirect to home page if already signed in
          router.replace('/home');
          return; // Exit early to prevent further execution
        }
      }

      setIsLoading(false); // Stop loading after all checks
    };

    // Trigger checkAuthentication only on initial mount or when dependencies change
    if (router.isReady) {
      checkAuthentication();
    }
  }, [router.isReady, id, status, accessType]); // Ensure to monitor changes to `id` and `accessType`

  // Monitor changes to `tabId` without triggering a full page reload
  useEffect(() => {
    if (tabId) {
      // Handle tabId changes as needed without setting loading state or rechecking ID
      // console.log(`Tab changed to: ${tabId}`);
      // You can add additional logic here if needed
    }
  }, [tabId]);

  // Render loader while loading or if ID is not valid
  if (isLoading || !isValidId) {
    return <Loader />;
  }

  // Render children only when loading is false and ID is valid
  return <>{children}</>;
}
