import { createAsyncThunk } from '@reduxjs/toolkit';
import { type } from 'os';
import { apiServiceInstance } from '../apiUtils';
import { UsersSlice } from '../Reducers/loginReducer';
import {
  checkExistingUserObjProp,
  forgotPasswordObjProp,
  loginObjProp,
  productToursType,
  setUserStatusObjType,
  signUpUserObjProp,
} from '../Reducers/Types';

export const loginUser = createAsyncThunk(
  'loginUser',
  async (loginObj: loginObjProp) => {
    const res = await apiServiceInstance.post('/login/', loginObj);
    return res;
  },
);

type authProp = loginObjProp | null;

export const auth = createAsyncThunk(
  'login/auth',
  async (loginObj: authProp, { dispatch }) => {
    if (!!loginObj) await dispatch(loginUser(loginObj));
    const response = await apiServiceInstance.post('/auth/');
    return response.data;
  },
);

export const authCheck = createAsyncThunk(
  'authCheck',
  async () => {
    const response = await apiServiceInstance.post('/auth/');
    return response.data;
  },
);

export const checkExistingUser = createAsyncThunk(
  'checkExistingUser',
  async (emailObj: checkExistingUserObjProp) => {
    const res = await apiServiceInstance.get(
      '/register/?email=' + emailObj.email,
    );
    return res;
  },
);

export const signUpUser = createAsyncThunk(
  'signUpUser',
  async (signObj: signUpUserObjProp) => {
    const res = await apiServiceInstance.post('/register/', signObj);
    return res;
  },
);

export const forgotPassword = createAsyncThunk(
  'forgotPassword',
  async (forgotPasswordObj: forgotPasswordObjProp) => {
    const res = await apiServiceInstance.post(
      '/password/reset',
      forgotPasswordObj,
    );
    return res;
  },
);

export type resetPasswordProp = {
  oldPassword: string;
  newPassword: string;
};

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (payload: resetPasswordProp) => {
    const res = await apiServiceInstance.post('/password/change', payload);
    if (res.data?.status !== 'success') {
      throw new Error('Incorrect Password Provided');
    }
    return res;
  },
);

export const logoutUser = createAsyncThunk('logoutUser', async () => {
  const res = await apiServiceInstance.post('/logout/');
  return res;
});

export type updateProfileProp = {
  userName?: string;
  profilePicUrl?: string;
  user_id?: number;
};

export const updateProfile = createAsyncThunk(
  'updateProfile',
  async (updateProfile: updateProfileProp) => {
    await apiServiceInstance.put('/auth/profile', updateProfile);
    return updateProfile;
  },
);

export const getProductTours = createAsyncThunk('getProductTours', async () => {
  const res = await apiServiceInstance.get('/tour/');
  return res.data?.value;
});

export const addTourStatus = createAsyncThunk(
  'addTourStatus',
  async (payload: productToursType) => {
    await apiServiceInstance.post('/tour/', payload);
    return payload;
  },
);

export const setUserStatus = async (setUserStatusObj: setUserStatusObjType) => {
  const res = await apiServiceInstance.post(
    '/SetUserStatus/',
    setUserStatusObj,
  );
  return res;
};

export const getPolicyData = async () => {
  const res = await apiServiceInstance.post('/auth/policy');
  return res;
};

export const agreePolicy = async (policyId: { policyId: number }) => {
  const res = await apiServiceInstance.put('/auth/policy', policyId);
  return res;
};
